<template>

    <div class="wrapper">
        <div class="animated fadeIn">
            <!-- 제품키 정보 --------------------------------------------------------------------------------------------->
            <BCard>
                <BCard header-tag="header" footer-tag="footer" class="mb-1" >

                    <div slot="header">
                        <BIconKeyFill/> <strong> 제품키 </strong>
                        <div class="card-header-actions">
                            <small class="text-muted">제품키 리스트</small>
                        </div>
                    </div>

                    <BRow class="mb-2">
                        <BCol sm="3">
                            <strong>Total Records: {{ rows.length }}</strong>
                        </BCol>
                        <BCol sm="4">
                            <BInputGroup >
                                <template v-slot:prepend>
                                    <BFormSelect size="sm"
                                                 v-model="searchField"
                                                 :options="searchOpts"/>
                                </template>
                                <BFormInput size="sm"
                                            v-model="searchWord"
                                            type="text"
                                            @keyup.enter="getProductKeyList"/>
                            </BInputGroup>
                        </BCol>
                        <BCol>
                            <BButton size="sm" variant="info" @click="exportExcel"><BIconFileSpreadsheetFill/> 파일 다운로드</BButton>
                        </BCol>
                    </BRow>

                    <BRow>
                        <BCol>
                            <vue-excel-editor
                                    v-model="rows"
                                    ref="productKeyGrid"
                                    width="100%"
                                    class="mb-1"
                                    :page="numOfRecord"
                                    :readonly-style="{backgroundColor:'#EFE'}"
                                    @update="updateProductKey"
                                    @delete="deleteProductKey"
                                    @select="selectProductKey"
                                    :localized-label="$localizedLabel"
                                    filter-row>
                                <vue-excel-column field="key_seq" label="키번호" type="number" width="60px" key-field readonly/>
                                <vue-excel-column field="disp_key" label="제품키" width="300px" readonly/>
                                <vue-excel-column field="prd_code" label="상품명" width="150px" type="map" :options="prdCodeOpts"/>
                                <vue-excel-column field="prd_name" label="제품명" width="100px"/>
                                <vue-excel-column field="ord_no" label="Order" type="number" width="60px"/>
                                <vue-excel-column field="name" label="이름" width="60px" readonly/>
                                <vue-excel-column field="comp_name" label="회사명" width="100px" readonly/>
                                <vue-excel-column field="version" label="버전" width="50px"/>
                                <vue-excel-column field="auth_stat" label="상태" type="map" width="80px" :options="prdStatusOpt" readonly/>
                                <vue-excel-column field="warranty_mon" label="워런티(월)" type="number" width="80px"/>
                                <vue-excel-column field="cert_file" label="인증서" :link="showDocument" width="140px"/>
                                <vue-excel-column field="auth_start" label="최초인증" type="date" width="110px" readonly/>
                                <vue-excel-column field="auth_latest" label="최종인증" type="date" width="110px" readonly/>
                                <vue-excel-column field="exp_dt" label="만료일" type="date" width="110px"/>
                                <vue-excel-column field="auth_cnt" label="인증횟수" type="number" width="80px" readonly/>
                                <vue-excel-column field="allow_cnt" label="허용횟수" type="number" width="80px"/>
                                <vue-excel-column field="reg_dt" label="등록일" type="date" width="110px" readonly/>
                            </vue-excel-editor>
                        </BCol>
                    </BRow>
                    <BRow>
                        <BCol>
                            <BCard>
                                <div slot="header">
                                    <BIconCreditCard2FrontFill/> <strong> 발급된 토큰  <BBadge variant="info">{{dispKey}}</BBadge> </strong>
                                    <div class="card-header-actions">
                                        <small class="text-muted">인증토큰 리스트</small>
                                    </div>
                                </div>
                                <vue-excel-editor
                                        v-model="tokens"
                                        ref="productTokenGrid"
                                        width="100%"
                                        class="mb-1"
                                        :page="20"
                                        :localized-label="$localizedLabel"
                                        filter-row>
                                    <vue-excel-column field="token_seq" label="토큰번호" type="number" width="70px" key-field readonly/>
                                    <vue-excel-column field="disp_key" label="제품키" width="100px" readonly/>
                                    <vue-excel-column field="ord_no" label="Order" type="number" width="60px"/>
                                    <vue-excel-column field="token_stat" label="상태" type="map" :options="tokenStatusOpt" width="80px"/>
                                    <vue-excel-column field="exp_dt" label="만료일" type="date" width="110px"/>
                                    <vue-excel-column field="version" label="버전" width="60px"/>
                                    <vue-excel-column field="auth_cnt" label="인증횟수" type="number" width="80px" readonly/>
                                    <vue-excel-column field="accs_yn" label="실행" type="map" width="60px" :options="{'Y':'실행', 'N':'종료'}"/>
                                    <vue-excel-column field="accs_dt" label="접속일시"  type="datetime" width="110px" readonly/>
                                    <vue-excel-column field="close_dt" label="종료일시"  type="date" width="110px" readonly/>
                                    <vue-excel-column field="accs_ip" label="IP"  width="140px" readonly/>
                                    <vue-excel-column field="upd_dt" label="수정일" type="datetime" width="150px" readonly/>
                                    <vue-excel-column field="reg_dt" label="등록일" type="datetime" width="150px" readonly/>
                                </vue-excel-editor>
                            </BCard>
                        </BCol>
                    </BRow>

                </BCard>


            </BCard>

        </div>
    </div>
</template>



<script>
    //-------------------------------------------------------------------------------------------------
    import {alertModal, apiCall, cloneVar, confirmModal, checkBisNo, alertWarn} from '../../../common/utils';
    import qs from 'querystring';
    // import VueEditableGrid from "vue-editable-grid";
    // import VueExcelEditor from 'vue-excel-editor'
    // import JsonExcel from "vue-json-excel";
    import moment from "moment";
    // import pdf from 'vue-pdf';


    //----------------------------------------------------------------------------------------------------
    export default {
        name: 'ProductKey',

        data () {
            return {
                prdCodeOpts: [],
                searchOpts: [
                    {value:'prd_key', text:'제품키'},
                    {value:'name', text:'이름/회사'},
                    {value:'prd_name', text:'제품명'},
                    {value:'auth_stat', text:'인증상태'},
                    {value:'version', text:'버전'}
                ],
                prdStatusOpt: {'99':'생성','00':'인증','10':'주문','90':'중지','91':'철회' },
                tokenStatusOpt: {'00':'활성', '10':'준비', '90': '추출', '99':'만료'},
                rows: [],
                tokens: [],
                selectedIdx: null,
                selectedRow: null,
                gridKey: 0,
                isCancelBtnShow: false,

                updateRows: new Set(),
                deleteRows: new Set(),
                addedRows: new Set(),
                deleteCount: 0,

                /////////////////////////////////////////////////////
                searchWord : null,
                searchField: 'prd_key',
                compActionMode: 'POST',
                comp: null,
                compItems : [],
                numOfRecord: 20,
                productKeyFileName: `product-key-${moment().format('YYYYMMDD')}.xls`,
                pdfFile: null,
                dispKey: null,

            }

        },
        async created(){
            try{
                await this.getPrdCode();

                await this.getProductKeyList();

            }catch(err){

            }


        },
        computed: {

        },
        mounted() {
            console.log("mounted---------------------ProductKey");
            // below is not work!
        },

        methods: {
            async getPrdCode(){
                // console.log( "getPrdCode ---- 1" );
                this.prdCodeOpts = {};
                try{
                    const r = await apiCall('get', `/admin/api/product/info`);
                    r.result.map(i=>{this.prdCodeOpts[i.prd_code] = i.disp_name;});
                    // console.log( "prdCodeOpts ----> ", this.prdCodeOpts );

                }catch(err){}
            },

            async getProductKeyList(){

                let r = null, param = {};
                let qry = '';
                if( this.searchWord !== null){
                    param[this.searchField] = this.searchWord;
                    qry = qs.stringify( param );
                }
                try{
                    r = await apiCall('get', `/admin/api/product/key?${qry}`);
                    this.rows = r.result;

                    this.selectedIdx = null;
                    this.selectedRow = null;

                    if( this.updateRows.size || this.deleteRows.size ){
                        this.updateRows.clear();
                        this.deleteRows.clear();
                    }

                }catch(err){
                    console.log( err );
                }

            },

            exportExcel(){
                const format = 'xlsx';
                const exportSelectedOnly = true;
                const filename = 'product-key-'+moment().format('YYYY-MM-DD');
                this.$refs.productKeyGrid.exportTable(format, exportSelectedOnly, filename)
            },

            async getProductTokenList(rec){
                console.log( "productTokenList ---------------> ",rec);
                let r = null, param = { key_seq: rec.key_seq };
                let qry = '';
                qry = qs.stringify( param );
                console.log( "query-string ---------- ", qry );
                try{
                    r = await apiCall('get', `/admin/api/product/token?${qry}`);
                    if(r.code===200) this.tokens = r.result;
                }catch(err){
                    console.log( err );
                }
            },
            async selectProductKey(idx, evt){
                this.tokens = [];
                this.dispKey = '';
                if(!evt) return;
                try{
                    const arr = this.$refs['productKeyGrid'].getSelectedRecords();
                    this.dispKey = arr[0].disp_key;
                    await this.getProductTokenList( arr[0] );

                }catch(err){
                    console.log(err);
                }
            },
            async showDocument(obj){
                console.log("showDocument ---------> ", obj);
                try{
                    const r = await apiCall('download', `/admin/api/product/document/${obj}`);
                    console.log( r );
                    const file = new Blob(
                      [r.data],
                      {type: 'application/pdf'});
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    window.open(fileURL);
                    // this.pdfFile = `data:application/pdf,${escape(r.data)}`;
                }catch(err){
                    console.log(err);
                }
            },

            async deleteProductKey(recs){
                let r = null, msg = null, notice = null;

                try{
                    console.log( recs );
                    /*
                    r = await apiCall("DELETE", `/api/setting/company/${this.agt.comp_no}`, agt);
                    msg = r.error;

                    if( r.error.code==='200'){
                        notice = {title: `[${msg.code}] 성공`, text : '삭제 되었습니다.', level: 'warn'};
                    }else{
                        notice = {title: `[${msg.code}] 에러`, text : msg.title, level: 'err'};
                    }

                    alertModal( this.$bvModal, notice, (ok)=>{
                        if(ok) this.getCompList();
                    });*/
                }catch(err){

                    console.log( err );
                }
            },

            async updateProductKey(rec){
                let r = null;

                try{
                    let param = {};
                    const keySeq = rec[0].keys[0];
                    const fieldName = rec[0].name;
                    const newVal = rec[0].newVal;

                    param['key_seq'] = keySeq;
                    param[fieldName] = newVal;

                    r = await apiCall("PUT", `/admin/api/product/key`, param);
                    if( !r.result || !r.result.affectedRows ){
                        await alertWarn( this.$bvModal, `Message: ${r.message}`, "[에러] 업데이트 실패");
                        this.$refs.productInfoGrid.undoTransaction();
                    }


                }catch(err){
                    console.log( err );
                }
            },




        }
    }
</script>
